<template>
  <div :id='itemkey'
       :style='rootStyle'>
  </div>
</template>

<script>
import 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
export default {
  name: 'hlsvideo',
  data: function () {
    return {
      player: null
    }
  },
  props: {
    config: {
      type: Object,
      default () {
        return { id: 'mse' }
      }
    },
    url: {
      type: String,
      default: ''
    },
    itemkey: {
      type: String,
      default: ''
    },
    rootStyle: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    init () {
      const { url, itemkey: id } = this
      if (url && url !== '') {
        console.log({ ...this.config, url, id })
        this.player = new HlsJsPlayer({ ...this.config, url, id });
        this.$emit('player', this.player)
      } else {
        setTimeout(() => {
          this.init()
        }, 500)
      }
    }
  },
  mounted () {
    this.init();
  },
  beforeUpdate () {
    this.init();
  },
  beforeDestroy () {
    this.player && typeof this.player.destroy === 'function' && this.player.destroy();
  }

}
</script>